/** @format */
//@ts-nocheck
import { notification } from "antd";
import React, { CSSProperties } from "react";
import { UIButton } from "../components/ui/Button";

const notificationStyle = {
  borderRadius: "7px",
  whiteSpace: "break-spaces",
};

const notificationStyleXL: CSSProperties = {
  borderRadius: "7px",
  whiteSpace: "break-spaces",
  scale: "1.5",
  marginTop: "20px",
};

export const showNotification = (
  type: "error" | "warning" | "success" | "notification",
  message: string,
  description: string,
  duration?: number = 3,
  style?: "M" | "XL" = "M",
  placement?: "top" | "bottom" | "topLeft" | "topRight" | "bottomLeft" | "bottomRight" = "topRight",
): void => {

  if(notification){
    notification.destroy();
  }

  const options = {
    message,
    description,
    style: style == "M" ? notificationStyle : notificationStyleXL,
    duration,
    placement,
  };
  switch (type) {
    case "error":
      notification.error({ ...options });
      break;
    case "warning":
      notification.warn({ ...options });
      break;
    case "success":
      notification.success({ ...options });
      break;
    case "notification":
      notification.open({ ...options });
      break;
    default:
      notification.open({ ...options });
      break;
  }
};

export const showNotificationWithButton = (
  type: "error" | "warning" | "success" | "notification",
  message: string,
  description: string,
  style?: "M" | "XL" = "M",
  placement?: "top" | "bottom" | "topLeft" | "topRight" | "bottomLeft" | "bottomRight" = "topRight",
): void =>{
  const key = `open${Date.now()}`;
  const options = {
      message,
      description,
      duration: 0,
      style: style == "M" ? notificationStyle : notificationStyleXL,
      placement,
      key,
      btn: <UIButton type="primary" size="small" onClick={() => notification.close(key)}>Chiudi</UIButton>
    };
    switch (type) {
      case "error":
        notification.error({ ...options });
        break;
      case "warning":
        notification.warn({ ...options });
        break;
      case "success":
        notification.success({ ...options });
        break;
      case "notification":
        notification.open({ ...options });
        break;
      default:
        notification.open({ ...options });
        break;
    }
}
