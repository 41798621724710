/** @format */

import React, { useEffect, useState } from "react";
import { Col, Row, Skeleton, Slider } from "antd";
import { UITitle } from "../../ui/Title";
import { BaseColor, Colors } from "../../../theme/maps/Colors";
import { UIButton } from "../../ui/Button";
import { UICard } from "../../ui/Card";
import UITransition from "../../ui/Transition";
import { useDispatch, useSelector } from "react-redux";
import { selectorPermissionList } from "../../../redux-modules/selectors/selectorsPermissions";
import { hasPermission } from "../../../utils/permissions/hasPermission";
import styled from "styled-components";
import { UIText } from "../../ui/Text";
import { selectorPresenceTypeList } from "../../../redux-modules/selectors/selectorsPresenceTypes";
import { ShowPresenceDrawer } from "../../layout/SideDialogs/ShowPresence";
import { PresenceCalendar } from "../../layout/PresenceCalendar";
import { CreatePresenceDrawer, TPresenceCreateObject } from "../../layout/SideDialogs/AddPresence";
import { TUser } from "../../../redux-modules/declarations/maps/auth";
import { API_ENDPOINTS } from "../../../constants/api";
import apiClient from "../../../utils/apiClient";
import { selectorJustificativeList } from "../../../redux-modules/selectors/selectorsJustificatives";
import moment from "moment";
import { selectorCustomStyleOptions } from "../../../redux-modules/selectors/selectorsUI";
import { CustomStyleProvider } from "../../utils/CustomStyleProvider";
import { SettingOutlined } from "@ant-design/icons";
import { actionCustomStyleOptionUpdateRequest } from "../../../redux-modules/actions/actionsUI";
import { UICheckbox } from "../../ui/Checkbox";
import { StyledCheckbox } from "../../layout/SideDialogs/ImportMalattia";
import { actionRequestCreateRequest } from "../../../redux-modules/actions/actionsRequest";
import { TRequestCreateRequest } from "../../../redux-modules/declarations/maps/requests";
import { RequestCreateDrawer } from "../../layout/SideDialogs/RequestCreate";

export const ViewPresencesList = (): JSX.Element => {
  const [showPresenceVisible, setShowPresenceVisible] = useState(false);
  const presenceTypeList = useSelector(selectorPresenceTypeList);
  const justificativeList = useSelector(selectorJustificativeList);
  const [users, setUsers] = useState<TUser[] | null>(null);
  const [showCustomStyleOptions, setShowCustomStyleOptions] = useState(false);
  const [currentInitialValues, setCurrentInitialValues] = useState<TPresenceCreateObject | null>(
    null,
  );
  const dispatch = useDispatch();
  const customStyleOptions = useSelector(selectorCustomStyleOptions);
  const [zoomLevel, setZoomLevel] = useState(customStyleOptions["PresencesListZoomLevel"]);
  const [showLocationName, setShowLocationName] = useState(
    customStyleOptions.showOfficeLabelInPresenceList,
  );
  const [showHandonShifts, setShowHandonShifts] = useState(
    customStyleOptions.showHandonLabelInPresenceList,
  );

  useEffect(() => {
    if (
      customStyleOptions["PresencesListZoomLevel"] !== zoomLevel ||
      customStyleOptions.showOfficeLabelInPresenceList !== showLocationName ||
      customStyleOptions.showHandonLabelInPresenceList !== showHandonShifts
    ) {
      dispatch(
        actionCustomStyleOptionUpdateRequest({
          ...customStyleOptions,
          PresencesListZoomLevel: zoomLevel,
          showOfficeLabelInPresenceList: showLocationName,
          showHandonLabelInPresenceList: showHandonShifts,
        }),
      );
    }
  }, [zoomLevel, showLocationName, showHandonShifts]);

  const [currentDate, setCurrentDate] = useState(moment());
  const [needToUpdateAbsences, setNeedToUpdateAbsences] = useState(true);
  const [needToUpdateAll, setNeedToUpdateAll] = useState(false);
  const onShowPresenceClose = (): void => setShowPresenceVisible(false);
  const permissionList = useSelector(selectorPermissionList);
  const canAddPresence = hasPermission(permissionList, "Presence", "Create");
  const [creatingPresence, setCreatingPresence] = useState(false);

  const canAddRequest = hasPermission(permissionList, "Request", "Canmanage");
  const [newRequestVisible, setNewRequestVisible] = useState(false);
  const onNewRequestClose = (): void => setNewRequestVisible(false);
  const showNewRequestDrawer = (): void => setNewRequestVisible(true);
  const handleNewRequestSave = (info: TRequestCreateRequest): void => {
    dispatch(actionRequestCreateRequest(info));
    onNewRequestClose();

    setNeedToUpdateAll(true);
    setNeedToUpdateAll(false);
  }; 

  const handleCreatePresenceModalClose = () => {
    setCurrentInitialValues(null);
    setCreatingPresence(false);
  };

  const setInitAndOpenModal = (obj: TPresenceCreateObject) => {
    setCurrentInitialValues(obj);
    setCreatingPresence(true);
  };

  const updateAbsences = () => {
    setNeedToUpdateAbsences(true);
    setNeedToUpdateAbsences(false);
  };

  useEffect(() => {
    apiClient.get(API_ENDPOINTS.USERS_LIST + "?all=true").then(res => {
      setUsers(res.data.data);
    });
  }, []);

  useEffect(() => {
    console.log(currentInitialValues);
  }, [currentInitialValues]);

  return (
    <UITransition>
      <CustomStyleProvider section="Presences">
        <Row justify="space-between" align="middle" gutter={[16, 16]}>
          <Col style={{ display: "inline-flex", alignItems: "baseline" }}>
            <UITitle level={2} color={BaseColor}>
              Presenze
            </UITitle>
            <span
              style={{
                fontSize: "18px",
                color: BaseColor,
                cursor: "pointer",
                marginLeft: "10px",
                position: "relative",
                top: "-3px",
              }}
              onClick={() => setShowCustomStyleOptions(!showCustomStyleOptions)}
            >
              <SettingOutlined />
            </span>
          </Col>
          <span style={{ display: "flex" }}>
            {canAddPresence && (
              <Col>
                <UIButton type="primary" onClick={() => setCreatingPresence(true)}>
                  + Nuova Presenza
                </UIButton>
              </Col>
            )}
            {canAddRequest && (
                <Col>
                  <UIButton type="primary" onClick={showNewRequestDrawer}>
                    + Nuova Richiesta
                  </UIButton>
                </Col>
              )}
          </span>
        </Row>
        {showCustomStyleOptions && (
          <UITransition>
            <Row gutter={[8, 8]} style={{ marginBottom: "10px" }}>
              <Col span={24}>
                <UICard>
                  <Row gutter={[8, 8]}>
                    <Col>
                      <UITitle level={5} color={BaseColor}>
                        Personalizza pagina
                      </UITitle>
                    </Col>
                  </Row>
                  <Row gutter={[8, 8]}>
                    <Col span={8}>
                      <UIText>Livello di zoom</UIText>
                      <Slider
                        min={0.7}
                        max={1}
                        onChange={(value: number) => setZoomLevel(value)}
                        value={typeof zoomLevel === "number" ? zoomLevel : 0}
                        step={0.1}
                        tooltipVisible={false}
                        marks={{
                          0.7: "70%",
                          0.8: "80%",
                          0.9: "90%",
                          1: "100%",
                        }}
                      />
                    </Col>
                    <Col span={4}>
                      <Row justify="center">
                        <Col>
                          <UIText>Mostra nome sedi</UIText>
                        </Col>
                      </Row>
                      <Row justify="center" style={{ marginTop: "10px" }}>
                        <Col>
                          <StyledCheckbox
                            onChange={val => setShowLocationName(val.target.checked)}
                            value={showLocationName}
                            defaultChecked={showLocationName}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col span={4}>
                      <Row justify="center">
                        <Col>
                          <UIText>Evidenzia modifiche</UIText>
                        </Col>
                      </Row>
                      <Row justify="center" style={{ marginTop: "10px" }}>
                        <Col>
                          <StyledCheckbox
                            onChange={val => setShowHandonShifts(val.target.checked)}
                            value={showHandonShifts}
                            defaultChecked={showHandonShifts}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </UICard>
              </Col>
            </Row>
          </UITransition>
        )}
        <CustomCard>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <CustomDiv>
                {!presenceTypeList.length && (
                  <span style={{ height: "20px" }}>
                    <Skeleton.Input active size={"small"} style={{ marginRight: "20px" }} />
                    <Skeleton.Input active size={"small"} style={{ marginRight: "20px" }} />
                    <Skeleton.Input active size={"small"} style={{ marginRight: "20px" }} />
                    <Skeleton.Input active size={"small"} style={{ marginRight: "20px" }} />
                  </span>
                )}
                {presenceTypeList.map(presenceType => (
                  <span style={{ marginRight: "25px" }} key={presenceType.id}>
                    <span
                      style={{
                        backgroundColor: presenceType.color,
                        borderRadius: "3px",
                        marginRight: "3px",
                      }}
                    >
                      {"\u00A0"}
                      {"\u00A0"}
                      {"\u00A0"}
                      {"\u00A0"}
                      {"\u00A0"}
                    </span>
                    <UIText>{presenceType.name}</UIText>
                  </span>
                ))}
                {presenceTypeList.length ? (
                  <>
                    {justificativeList.data.map(justificative => (
                      <span style={{ marginRight: "25px" }} key={justificative.id}>
                        <span
                          style={{
                            backgroundColor: justificative.color,
                            borderRadius: "3px",
                            marginRight: "3px",
                          }}
                        >
                          {"\u00A0"}
                          {"\u00A0"}
                          {"\u00A0"}
                          {"\u00A0"}
                          {"\u00A0"}
                        </span>
                        <UIText>{justificative.name}</UIText>
                      </span>
                    ))}
                    <span style={{ marginRight: "25px" }} key={"mancata_badgiatura"}>
                      <span
                        style={{
                          backgroundColor: Colors.giarno,
                          borderRadius: "3px",
                          marginRight: "3px",
                        }}
                      >
                        {"\u00A0"}
                        {"\u00A0"}
                        {"\u00A0"}
                        {"\u00A0"}
                        {"\u00A0"}
                      </span>
                      <UIText>Mancata Timbratura</UIText>
                    </span>
                  </>
                ) : (
                  <></>
                )}
              </CustomDiv>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <PresenceCalendar
                needToUpdateAbsences={needToUpdateAbsences}
                needToUpdateAll={needToUpdateAll}
                setInitialValues={setInitAndOpenModal}
                onChangeCurrentDate={(x: moment.Moment) => {
                  setCurrentDate(x);
                }}
              />
            </Col>
          </Row>
        </CustomCard>
        <ShowPresenceDrawer onClose={onShowPresenceClose} visible={showPresenceVisible} />
        <CreatePresenceDrawer
          visible={creatingPresence}
          modalLoading={false}
          users={users}
          currentDate={currentDate}
          initialValues={currentInitialValues}
          onClose={handleCreatePresenceModalClose}
          updateAbsences={updateAbsences}
        />
        <RequestCreateDrawer
          onClose={onNewRequestClose}
          visible={newRequestVisible}
          onSave={handleNewRequestSave}
          canGetAllUsers={false}
          helperUsersList={users ?? []}
        />
      </CustomStyleProvider>
    </UITransition>
  );
};

const CustomDiv = styled.div`
  padding: 15px;
  margin-bottom: 10px;
  background-color: #fafafa;
  border-radius: 7px;
`;
const CustomCard = styled(UICard)`
  .ant-card-body {
    padding: 10px;
  }
`;
