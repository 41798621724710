/** @format */

import moment from "moment";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actionGroupsListRequest } from "../actions/actionsGroups";
import { actionJustificativeListRequest } from "../actions/actionsJustificatives";
import { actionRequestListRequest } from "../actions/actionsRequest";
import { TRequest } from "../declarations/maps/requests";
import { selectorLoggedUser } from "../selectors/selectorsAuth";
import { selectorJustificativeList } from "../selectors/selectorsJustificatives";
import {
  selectorRequestsList,
  selectorRequestsOwners,
  selectorRequestsOwnersUnique,
  TRequestsOwner,
} from "../selectors/selectorsRequests";
import { selectorRequestListLoading, selectorRequestListUpdated } from "../selectors/selectorsUI";

interface useRequestsReturn {
  requestList: TRequest[];
  personalRequestList: () => TRequest[];
  isRequestListLoading: boolean;
  isRequestListUpdated: boolean;
  requestsOwnersUnique: TRequest[];
  requestsOwners: TRequestsOwner[];
  updateRequestList: (dateRange: string[]) => void;
  getRequest: (id: string) => TRequest | undefined;
}

export const useRequests = (dates: string[], canGroupsListRequest: boolean = true): useRequestsReturn => {
  const justificatives = useSelector(selectorJustificativeList);
  const requestsList = useSelector(selectorRequestsList).map(el => ({
    ...el,
    color: justificatives.data.find(just => el.justificative_type_id === just.id)?.color,
  }));
  const requestsListLoading = useSelector(selectorRequestListLoading);
  const requestsListUpdated = useSelector(selectorRequestListUpdated);
  const loggedUser = useSelector(selectorLoggedUser);
  const requestsOwnersUnique = useSelector(selectorRequestsOwnersUnique);
  const requestsOwners = useSelector(selectorRequestsOwners);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!!dates.length) {
      if(canGroupsListRequest){
        //@ts-ignore
        dispatch(actionGroupsListRequest({ page_size: 100 }));
      }
      dispatch(actionJustificativeListRequest({}));
      dispatch(
        actionRequestListRequest({
          datetime_from: moment(dates[0]).format("YYYY-MM-DD"),
          datetime_to: moment(dates[dates.length - 1]).format("YYYY-MM-DD"),
        }),
      );
    }
  }, []);

  const updateRequestList = (dateRange: string[]): void => {
    dispatch(
      actionRequestListRequest({
        datetime_from: moment(dateRange[0]).format("YYYY-MM-DD"),
        datetime_to: moment(dateRange[dateRange.length - 1]).format("YYYY-MM-DD"),
      }),
    );
  };

  const myRequests = (): TRequest[] => {
    if (!loggedUser) return [];
    return requestsList.filter(e => e.user_id == loggedUser.id);
  };

  const getRequest = (id: string): TRequest | undefined => requestsList.find(e => e.id == id);

  useEffect(() => {
    if (requestsListUpdated) {
      updateRequestList(dates);
    }
  }, [requestsListUpdated]);

  return {
    isRequestListLoading: requestsListLoading,
    isRequestListUpdated: requestsListUpdated,
    requestList: requestsList,
    personalRequestList: myRequests,
    requestsOwnersUnique: requestsOwnersUnique,
    requestsOwners: requestsOwners,
    updateRequestList,
    getRequest: getRequest,
  };
};
