/** @format */

import { Row, Col, Form, Cascader, Checkbox, Empty, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { BaseColor } from "../../../theme/maps/Colors";
import { Routes } from "../../../utils/Routes";
import { UIButton } from "../../ui/Button";
import { UICard } from "../../ui/Card";
import { UIInput } from "../../ui/Input";
import { UILink } from "../../ui/Link";
import { UIText } from "../../ui/Text";
import { UITitle } from "../../ui/Title";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertToRaw } from "draft-js";
import styled from "styled-components";
import { useForm } from "antd/es/form/Form";
import draftToHtml from "draftjs-to-html";
import { SearchOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { API_ENDPOINTS } from "../../../constants/api";
import { actionCommunicationCreateRequest } from "../../../redux-modules/actions/actionsCommunications";
import { TUser } from "../../../redux-modules/declarations/maps/auth";
import apiClient from "../../../utils/apiClient";
import { UICheckbox } from "../../ui/Checkbox";
import { selectorCommunicationUpdated } from "../../../redux-modules/selectors/selectorsUI";
import { useHistory } from "react-router";

interface Option {
  value: string | number;
  label: string;
  children?: Option[];
}

export const ViewCommunicationCreate = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [form] = useForm();
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [userList, setUserList] = useState<TUser[]>([]);
  const [options, setOptions] = useState<Option[]>([]);
  const [selectedValues, setSelectedValues] = useState([]);
  const isCommunicationCreated = useSelector(selectorCommunicationUpdated);
  const [isUserLoading, setIsUserLoading] = useState(false);
  const [isCommunicationSending, setIsCommunicationSending] = useState(false);

  const handleSave = () => {
    setIsCommunicationSending(true);
    const content = editorState.getCurrentContent();
    const users = getUsersArray();
    const newCommunicationObj = {
      title: form.getFieldValue("title"),
      content: draftToHtml(convertToRaw(content)),
      user_ids: users,
      is_send_by_mail: form.getFieldValue("is_send_by_mail") || false,
    };
    dispatch(actionCommunicationCreateRequest(newCommunicationObj));
  };

  useEffect(() => {
    setIsUserLoading(true);
    apiClient.get(API_ENDPOINTS.USERS_LIST + "?all=true").then(res => {
      setUserList(res.data.data);
      setIsUserLoading(false);
    });
  }, []);

  useEffect(() => {
    if (isCommunicationCreated) history.push(Routes.comunications);
  }, [isCommunicationCreated]);

  useEffect(() => {
    if (userList) {
      let options: Option[] = [];
      userList.forEach(user => {
        //controllo se esiste già il gruppo
        user.groups.forEach(group => {
          if (options.find(el => el.value == "group_" + group.id)) {
            if (
              options
                .find(el => el.value == "group_" + group.id)
                ?.children?.find(e => e.value == "department_" + user.department.id)
            ) {
              options
                .find(el => el.value == "group_" + group.id)
                ?.children?.find(e => e.value == "department_" + user.department.id)
                ?.children?.push({
                  value: user.id,
                  label: user.surname + " " + user.name,
                }) || console.log("");
            } else {
              options
                .find(el => el.value == "group_" + group.id)
                ?.children?.push({
                  value: "department_" + user.department.id,
                  label: group.name + " - " + user.department.name,
                  children: [
                    {
                      value: user.id,
                      label: user.surname + " " + user.name,
                    },
                  ],
                }) || console.log("");
            }
          } else {
            options.push({
              value: "group_" + group.id,
              label: group.name,
              children: [
                {
                  value: "department_" + user.department.id,
                  label: group.name + " - " + user.department.name,
                  children: [{ value: user.id, label: user.surname + " " + user.name }],
                },
              ],
            });
          }
        });
      });
      setOptions(options);
    }
  }, [userList]);

  const onChangeFilters = (value: unknown) => {
    //@ts-ignore
    setSelectedValues(value);
  };

  const getUsersArray = (): string[] => {
    let users: string[] = [];
    if (selectedValues.length > 0) {
      selectedValues.forEach((value: string) => {
        // se gruppo
        if (value.length == 1) {
          let gruppo = value[0].split("_")[1];
          userList.forEach(user => {
            if (user.groups.find(group => group.id == gruppo)) {
              users.push(user.id);
            }
          });
        }
        // se dipartimento
        else if (value.length == 2) {
          let gruppo = value[0].split("_")[1];
          let dipartimento = value[1].split("_")[1];
          userList.forEach(user => {
            if (
              user.groups.find(group => group.id == gruppo) &&
              user.department.id == dipartimento
            ) {
              users.push(user.id);
            }
          });
        }
        // se utente
        else if (value.length == 3) {
          users.push(value[2]);
        }
      });
    } else {
      users = userList.map(user => user.id);
    }

    return users;
  };

  return (
    <>
      <Row justify="space-between" align="middle" gutter={[16, 16]}>
        <Col>
          <UITitle level={2} color={BaseColor} data-cy="page-title">
            Nuova Comunicazione
          </UITitle>
        </Col>
      </Row>
      <Form
        name="new-communication"
        className="new-communication"
        data-cy="new-communication-form"
        onFinish={handleSave}
        form={form}
        layout="vertical"
      >
        <Row justify="space-between" gutter={[16, 16]}>
          <Col xs={24}>
            <UICard>
              <Row gutter={[8, 8]}>
                <Col xs={10}>
                  <Form.Item
                    name="title"
                    label={<UIText strong={true}>Titolo</UIText>}
                    rules={[{ required: true, message: "Inserisic un titolo" }]}
                    extra="Il titolo della comunicazione"
                  >
                    <UIInput />
                  </Form.Item>
                </Col>
                <Col xs={4}>
                  <Form.Item
                    style={{ marginLeft: "5px" }}
                    name="is_send_by_mail"
                    label={<UIText strong={true}>Invia Mail</UIText>}
                    valuePropName="checked"
                  >
                    <StyledCheckbox />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[8, 8]}>
                <Col xs={24} md={24}>
                  <Form.Item
                    name="content"
                    label={<UIText strong={true}>Contenuto</UIText>}
                    rules={[
                      { required: true, message: "Inserisci il contenuto della comunicazione" },
                    ]}
                  >
                    <Editor
                      stripPastedStyles={true}
                      onEditorStateChange={setEditorState}
                      editorState={editorState}
                      wrapperClassName="wrap_style"
                      wrapperStyle={{ border: "1px solid #d9d9d9", borderRadius: "7px" }}
                      toolbarStyle={{
                        border: "none",
                        borderTopLeftRadius: "7px",
                        borderTopRightRadius: "7px",
                        borderBottom: "1px solid #d9d9d9",
                      }}
                      editorStyle={{ height: "300px" }}
                      localization={{
                        locale: "it",
                        translations: {
                          "components.controls.colorpicker.background": "Sfondo",
                        },
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col span={24}>
                  <Form.Item
                    name="user_ids"
                    label={<UIText strong={true}>Destinatari</UIText>}
                  >
                    <CustomCascader
                      style={{ width: "100%" }}
                      showSearch
                      loading={!!isUserLoading}
                      notFoundContent={
                        isUserLoading ? (
                          <Spin size="small" />
                        ) : (
                          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        )
                      }
                      options={options}
                      //@ts-ignore
                      onChange={onChangeFilters}
                      multiple
                      value={selectedValues}
                      placeholder={
                        <span>
                          <SearchOutlined style={{ marginRight: "5px" }} /> - Tutti -
                        </span>
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row justify="end" gutter={[24, 24]}>
                <Col>
                  <UILink route={Routes.comunications} label={<UIButton>Annulla</UIButton>} />
                </Col>
                <Col>
                  <Form.Item>
                    <UIButton
                      type="primary"
                      data-cy="new-communication-button"
                      htmlType="submit"
                      loading={isCommunicationSending}
                    >
                      + Invia Comunicazione
                    </UIButton>
                  </Form.Item>
                </Col>
              </Row>
            </UICard>
          </Col>
        </Row>
      </Form>
    </>
  );
};

const CustomCascader = styled(Cascader)`
  .ant-select-selector {
    border-radius: 7px !important;
  }
`;

const StyledCheckbox = styled(UICheckbox)`
  .ant-checkbox-inner {
    width: 32px;
    height: 32px;
    border-radius: 7px;
  }
  .ant-checkbox-inner:after {
    left: 10px;
  }
  .ant-checkbox {
    font-size: 0px;
  }
`;
