/** @format */

import { TUser } from "./auth";
import { TLocation } from "./locations";

export interface TPresence {
  id: string;
  datetime_from: string;
  datetime_to: string;
  presence_type_id: string;
  presence_type_name: string;
  presence_type_color: string;
  centesimal_duration: string;
  centesimal_datetime_from: string;
  ui_centesimal_duration: string;
  ui_centesimal_datetime_from: string;
  duration: string;
  user: TUser;
  user_id: string;
  user_name: string;
  office?: TLocation;
  history?: TPresenceHistory[];
  mancata_chiusura?: TCustomMancataChiusura
}

export interface TCustomMancataChiusura{
  shift_type_time_to: string,
  shift_type_time_to_centesimal: string,
  description: string,
}

export interface TAbsence {
  id: string;
  user_id: string;
  user_name: string;
  user: TUser;
  description: string;
  color: string;
  datetime_from: string;
  datetime_to: string;
  centesimal_duration: string;
  centesimal_datetime_from: string;
  is_partial_presence: boolean;
  shift_type_id?: string;
  is_mancata_timbratura_today?: boolean
  time_from_mancata_timbratura?: string;
  time_to_mancata_timbratura?: string;
  is_clickable?: boolean 
}

export interface TCustomPresence {
  original: TPresence;
  modified: TModifiedPresence;
}

export interface TModifiedPresence {
  created_at: string;
  delta_centesimal_duration: string;
  delta_duration: string;
  delta_ingresso_minuti: string;
  delta_second_duration: string;
  delta_uscita_minuti: string;
  ui_centesimal_duration: string;
  ui_centesimal_datetime_from: string;
  id: string;
  is_cron_closed: string;
  is_timebank_approved: string;
  note: string;
  presence_centesimal_duration: string;
  presence_centesimal_datetime_from: string;
  presence_datetime_from: string;
  presence_datetime_to: string;
  presence_duration: string;
  presence_id: string;
  presence_seconds_duration: string;
  presence_type_id: string;
  shift_planning_centesimal_duration: string;
  shift_planning_datetime_from: string;
  shift_planning_datetime_to: string;
  shift_planning_duration: string;
  shift_planning_id: string;
  shift_planning_seconds_duration: string;
  shift_type_id: string;
  tenance_id: string;
  updated_at: string;
  user_id: string;
  is_notturno: string;
}

export interface TPresenceHistory {
  id: string;
  user_id: string;
  user: string;
  event: string;
  note: string;
  date_operation: string;
}
export type TPresenceList = TPresence[];

export type TCustomPresenceList = TCustomPresence[];

export interface TPresences {
  presenceList: TCustomPresenceList;
}

export interface TPresenceCreateRequest {
  generate_date: string;
  user_id: string;
  presence_type_id?: string;
  device_code?: string;
  shift_planning_id?: string | null;
  origin: string;
}

export interface TPresenceListQuery {
  datetime_from: string;
  datetime_to: string;
  catafratto?: boolean;
}

export enum TPresenceExportType {
  PLAIN = 1,
  CSV = 3,
  TEAMSYSTEM = 4,
  TULIP = 5,
  XLS = 6,
  XLS_PRODUTTIVITA = 7,
  ZUCCHETTI = 100,
  CSV_WITH_CACHE = 55,
  PRODUTTIVITA = 56,
  TEAMSYSTEM_WITH_CACHE = 57,
  ZUCCHETTI_WITH_CACHE = 100,
  MALATTIA = 20,
  FERIE = 30,
  ANOMALIE = 40,
  REPORT_GIUSTIFICATIVI = 130,
  JOB = 140
}

export enum TJustificativeType{
  MALATTIA = "2"
}