/** @format */

import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { API_ENDPOINTS } from "../../constants/api";
import apiClient from "../../utils/apiClient";
import { actionGroupsListRequest } from "../actions/actionsGroups";
import { actionJustificativeListRequest } from "../actions/actionsJustificatives";
import { actionPresenceListRequest } from "../actions/actionsPresences";
import { actionPresenceTypeListRequest } from "../actions/actionsPresenceTypes";
import { actionRequestListRequest } from "../actions/actionsRequest";
import { actionUserListPageSizeRequest, actionUsersListRequest } from "../actions/actionsUsers";
import { TAbsence, TCustomPresence, TPresence } from "../declarations/maps/presences";
import { TRequest } from "../declarations/maps/requests";
import { TStore } from "../declarations/store";
import { selectorLoggedUser } from "../selectors/selectorsAuth";
import {
  selectorJustificative,
  selectorJustificativeList,
} from "../selectors/selectorsJustificatives";
import {
  selectorPresenceList,
  selectorPresencesOwnersUnique,
} from "../selectors/selectorsPresences";
import {
  selectorPresenceType,
  selectorPresenceTypeList,
} from "../selectors/selectorsPresenceTypes";
import {
  selectorRequestsList,
  selectorRequestsOwners,
  selectorRequestsOwnersUnique,
  TRequestsOwner,
} from "../selectors/selectorsRequests";
import {
  selectorPresenceListLoading,
  selectorRequestListLoading,
  selectorRequestListUpdated,
} from "../selectors/selectorsUI";

interface usePresencesReturn {
  presenceList: TCustomPresence[];
  isPresenceListLoading: boolean;
  presencesOwnersUnique: TCustomPresence[];
  updatePresenceList: (date: string) => void;
  updateAbsenceList: () => void;
  absenceList: TAbsence[];
  absenceOwnerUniqueList: TAbsence[];
  isAbsenceListLoading: boolean;
}

export const usePresences = (date: string): usePresencesReturn => {
  const [absenceList, setAbsenceList] = useState<TAbsence[]>([]);
  const [absenceOwnerUniqueList, setAbsenceOwnerUniqueList] = useState<TAbsence[]>([]);
  const presenceTypes = useSelector(selectorPresenceTypeList);
  const presenceList = useSelector(selectorPresenceList).map(el => ({
    ...el,
    color: presenceTypes.find(pt => el.original.presence_type_id === pt.id)?.color,
  }));
  const presenceListLoading = useSelector(selectorPresenceListLoading);
  const loggedUser = useSelector(selectorLoggedUser);
  const presencesOwnersUnique = useSelector(selectorPresencesOwnersUnique);
  const dispatch = useDispatch();

  const getAbsenceOwnersUnique = (absences: TAbsence[]) => {
    let uniqueIds: string[] = [];

    const uniqueAbsencesOwners = absences.filter(element => {
      const isDuplicate = element.user && uniqueIds.includes(element.user?.id);
      if (!isDuplicate && element.user) {
        uniqueIds.push(element.user.id);
        return true;
      }
      return false;
    });
    return uniqueAbsencesOwners;
  };

  useEffect(() => {
    //@ts-ignore
    dispatch(actionGroupsListRequest({ page_size: 100 }));
    dispatch(actionPresenceTypeListRequest());
    dispatch(
      actionPresenceListRequest({
        datetime_from: date,
        datetime_to: date,
      }),
    );
  }, []);

  const updatePresenceList = (date: string) => {
    dispatch(
      actionPresenceListRequest({
        datetime_from: date,
        datetime_to: date,
      }),
    );

    setIsAbsenceListLoading(true);
    apiClient.post(API_ENDPOINTS.ABSENCES, { date: date }).then(res => {
      setAbsenceList(res.data);
      setAbsenceOwnerUniqueList(getAbsenceOwnersUnique(res.data));
      setIsAbsenceListLoading(false);
    }).catch(error => {
      setIsAbsenceListLoading(false);
    });;
  };

  const updateAbsenceList = () => {
    setIsAbsenceListLoading(true);
    apiClient.post(API_ENDPOINTS.ABSENCES, { date: date }).then(res => {
      setAbsenceList(res.data);
      setAbsenceOwnerUniqueList(getAbsenceOwnersUnique(res.data));
      setIsAbsenceListLoading(false);
    }).catch(error => {
      setIsAbsenceListLoading(false);
    });;;
  };
  const isPresenceListLoading = useSelector(selectorPresenceListLoading);
  const [isAbsenceListLoading, setIsAbsenceListLoading] = useState(false);

  let presencesOwnersUniqueFixed = presencesOwnersUnique.map(usr => ({
    ...usr,
    has_anomalia: presenceList.some(
      pres =>
        pres.original.user_id === usr.original.user_id &&
        !!pres.modified &&
        !pres.modified.is_timebank_approved,
    ),
  }));
  return {
    isPresenceListLoading: presenceListLoading,
    presenceList: presenceList,
    presencesOwnersUnique: presencesOwnersUnique,
    updatePresenceList,
    updateAbsenceList,
    absenceList,
    absenceOwnerUniqueList,
    isAbsenceListLoading: isAbsenceListLoading
  };
};
