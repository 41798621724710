/** @format */

import React, { useEffect, useState } from "react";
import { Col, Row } from "antd";
import { UITitle } from "../../ui/Title";
import { BaseColor } from "../../../theme/maps/Colors";
import { UICard } from "../../ui/Card";
import UITransition from "../../ui/Transition";
import { useDispatch, useSelector } from "react-redux";
import {
  actionDocumentCreateRequest,
  actionDocumentListRequest,
} from "../../../redux-modules/actions/actionsDocument";
import { TablePaginationConfig } from "antd/lib/table";
import { useDebouncedInput } from "../../../redux-modules/hooks/useDebouncedInput";
import { selectorDocumentList } from "../../../redux-modules/selectors/selectorsDocuments";
import {
  selectorDocumentListLoading,
  selectorUserDocumentUpdated,
} from "../../../redux-modules/selectors/selectorsUI";
import { NewDocumentDrawer } from "./partials/NewDocumentDrawer";
import { TDocumentCreateRequest } from "../../../redux-modules/declarations/maps/documents";
import { UIFinder } from "../../ui/Finder";

export const ViewDocumentList = (): JSX.Element => {
  const dispatch = useDispatch();
  const [newDocumentVisible, setNewDocumentVisible] = useState(false);

  const documentList = useSelector(selectorDocumentList);
  const documentListLoading = useSelector(selectorDocumentListLoading);
  const isDocumentDeleted = useSelector(selectorUserDocumentUpdated);
  const isDocumentUploaded = useSelector(selectorUserDocumentUpdated);
  const [isDocumentUploading, setDocumentUploading] = useState(false);

  const onNewDocumentClose = (): void => setNewDocumentVisible(false);
  const showNewDocumentDrawer = (): void => setNewDocumentVisible(true);
  const handleNewDocumentSave = (info: TDocumentCreateRequest): void => {
    //dispatch save
    setDocumentUploading(true);
    dispatch(actionDocumentCreateRequest(info));
    // onNewDocumentClose();
  };

  useEffect(() => {
    if (!!isDocumentUploaded) {
      onNewDocumentClose();
      setDocumentUploading(false);
    }
  }, [isDocumentUploaded]);

  useEffect(() => {
    if (isDocumentDeleted) dispatch(actionDocumentListRequest());
  }, [isDocumentDeleted]);

  const [debouncedQuery, currentQuery, setQuery] = useDebouncedInput("", () => true, false, 600);

  const formattedDocumentList = documentList.map(e => ({ ...e, key: e.id }));

  const handleTableChange = (pagination: TablePaginationConfig): void => {
    dispatch(actionDocumentListRequest());
  };

  useEffect(() => {
    //dispatch(actionDocumentListRequest());
  }, [debouncedQuery]);

  useEffect(() => {
    dispatch(actionDocumentListRequest());
  }, []);

  return (
    <UITransition>
      <Row justify="space-between" align="middle" gutter={[16, 16]}>
        <Col>
          <UITitle level={2} color={BaseColor} data-cy="page-title">
            Documenti
          </UITitle>
        </Col>
        <Col>
          {/*
           <UIButton type="primary" onClick={showNewDocumentDrawer} data-cy="new-document-button">
            + Carica Documento
          </UIButton>
          */}
        </Col>
      </Row>
      <UICard>
        {/*
          <Row justify="space-between" gutter={[16, 16]}>
          <Col />
          <Col>
            <UISearch placeholder="Cerca..."  />
          </Col>
        </Row>
          */}

        <Row gutter={[16, 16]}>
          <Col span={24}>
            {/*
            <UITable
              size="middle"
              data-cy="document-list-table"
              loading={documentListLoading}
              columns={documentListColumns}
              dataSource={formattedDocumentList}
            />
            */}

            <UIFinder
              documents={formattedDocumentList}
              showExpiring
              onAddFile={showNewDocumentDrawer}
              loading={documentListLoading}
            />
          </Col>
        </Row>
      </UICard>
      <NewDocumentDrawer
        onClose={onNewDocumentClose}
        visible={newDocumentVisible}
        onSave={handleNewDocumentSave}
        loading={isDocumentUploading}
      />
    </UITransition>
  );
};
